// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-constructor-constructor__container--2YLU4{border:1px solid #BBBEBD;border-radius:5px;padding:6px;margin-bottom:10px;box-shadow:0 3px 6px rgba(0,0,0,0.16),0 3px 6px rgba(0,0,0,0.23);background-color:#EDECEB;color:#222}.components-constructor-constructor__constructorContainer--1LizB{position:relative;background-color:white;border-top-left-radius:4px;border-top-right-radius:4px}.components-constructor-constructor__constructor--25ds1{font-weight:700;font-family:'Roboto Mono', monospace;line-height:26px}.components-constructor-constructor__driversContainer--12ph6{border-top:1px solid #EDECEB;padding:6px 0;background-color:white;border-bottom-left-radius:4px;border-bottom-right-radius:4px}\n", ""]);
// Exports
exports.locals = {
	"container": "components-constructor-constructor__container--2YLU4",
	"constructorContainer": "components-constructor-constructor__constructorContainer--1LizB",
	"constructor": "components-constructor-constructor__constructor--25ds1",
	"driversContainer": "components-constructor-constructor__driversContainer--12ph6"
};
module.exports = exports;
