// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-driver-driver__driverContainer--3DVrK{position:relative}.components-driver-driver__driver_highlighted--1QZSq{font-weight:700}\n", ""]);
// Exports
exports.locals = {
	"driverContainer": "components-driver-driver__driverContainer--3DVrK",
	"driver_highlighted": "components-driver-driver__driver_highlighted--1QZSq"
};
module.exports = exports;
