// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-pairing-pairing__container--34Iem{width:-webkit-max-content;width:max-content}.components-pairing-pairing__yearContainer--2fgVa{margin-right:100px;display:inline-block;vertical-align:top;width:250px}.components-pairing-pairing__year---5WK0{text-align:center;font-size:32px;line-height:50px}\n", ""]);
// Exports
exports.locals = {
	"container": "components-pairing-pairing__container--34Iem",
	"yearContainer": "components-pairing-pairing__yearContainer--2fgVa",
	"year": "components-pairing-pairing__year---5WK0"
};
module.exports = exports;
