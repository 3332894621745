// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-header-header__header--vYTIE{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;padding:0 10px}.components-header-header__github--fQuXg{width:32px;height:32px;margin-right:20px}\n", ""]);
// Exports
exports.locals = {
	"header": "components-header-header__header--vYTIE",
	"github": "components-header-header__github--fQuXg"
};
module.exports = exports;
