// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-nameContainer-nameContainer__container--DWUAU{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;padding:6px 6px 6px 12px;-webkit-align-items:center;align-items:center;cursor:pointer}.components-nameContainer-nameContainer__score--1HDH_{border-radius:2px;background-color:rgba(0,0,0,0.6);color:white;padding:1px 3px;margin-left:5px;display:inline;font-size:10px;line-height:10px;font-family:'Roboto Mono', monospace;font-weight:400}.components-nameContainer-nameContainer__score_first--3UxoL{background-color:#F2D500}.components-nameContainer-nameContainer__score_second--8-uAf{background-color:#A7A7AD}.components-nameContainer-nameContainer__score_third--B2Cs1{background-color:#A77044}\n", ""]);
// Exports
exports.locals = {
	"container": "components-nameContainer-nameContainer__container--DWUAU",
	"score": "components-nameContainer-nameContainer__score--1HDH_",
	"score_first": "components-nameContainer-nameContainer__score_first--3UxoL",
	"score_second": "components-nameContainer-nameContainer__score_second--8-uAf",
	"score_third": "components-nameContainer-nameContainer__score_third--B2Cs1"
};
module.exports = exports;
