// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-page-page__page--ga3KC{padding:0 10px;overflow:scroll}\n", ""]);
// Exports
exports.locals = {
	"page": "components-page-page__page--ga3KC"
};
module.exports = exports;
